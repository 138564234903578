.artist-page {
    display: flex;
    flex-direction: column;
    
  }
  
  .artist-header {
    position: relative;
    
    width: 100%;
    height: 300px; /* Adjust height as needed */
    display: flex;
   
    justify-content: left;
   
    overflow: hidden;
  }

  .artist-content {
    display: flex;
    margin-left: 60px;
    align-items: center;
    
    gap: 20px;
  }

  .artist-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(4px) grayscale(100%);
    opacity: 0.5;
    z-index: -1;
  }

  .artist-content-img-name {
    justify-content: start;
    gap: 20px;
  }


  
  .artist-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-top: 20px;
  }
  
  .artist-name {
    font-size: 36px;
    font-family: 'Nohemi Regular';
    color: #2f2f2f;
  }
  
  .social-media-links {
    display: flex;
    justify-content: start;
    gap: 15px;
    margin-top: 10px;
  }

  .social-media-links img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   
  }
  
  .about-section {
    width: 90%;
    text-align: left;
    margin: 30px 30px;
  }
  
  .about-section h2 {
    font-size: 24px;
    font-family: 'Nohemi Regular';
  }
  
  .about-section p {
    font-size: 16px;
    font-family: 'Nohemi Light';
    color: #2f2f2f;
    margin: 20px 0;
  }
  
  .category-buttons {
    display: flex;
    justify-content: start;
    gap: 10px;
    margin-top: 20px;
  }
  
  .category-buttons button {
    font-family: 'Nohemi Regular';
    font-size: 16px;
    color: #2f2f2f;
    background-color: white;
    border: 2px solid #2f2f2f;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .category-buttons button.active {
    background-color: #2f2f2f;
    color: white;
}
  
  .category-buttons button:hover {
    background-color: #2f2f2f;
    border: 2px solid #2f2f2f;
    color: white;
  }

  


  .featured-songs {
    width: 100%;
    text-align: center;
  }
  
  .featured-songs h2 {
    font-size: 24px;
    font-family: 'Nohemi Regular';
    margin-bottom: 20px;
  }
  
  .featured-songs ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    
    list-style: none;
    padding: 0;
  }
  
  .featured-song {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    width: 200px;
    text-align: left;
    transition: transform 0.2s;
  }
  
  .featured-song:hover {
    transform: scale(1.05);
  }
  
  .song-thumbnail {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .song-details {
    padding: 10px;
  }
  
  .song-details span {
    display: block;
    font-family: 'Nohemi Regular';
  }
  
  .song-details span:first-child {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .song-details span:last-child {
    font-size: 16px;
    color: #666;
  }
  

  .cards-artist-page {
    display: flex;
    justify-content: start;
    padding-bottom: 50px;
    gap: 30px;
    padding-left: 30px;
    justify-content: flex-start;
  }
  
  .videos-container {
    background-color: transparent;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .youtube-videos-artist {
    border-radius: 30px;
  }

  /* Mobile-specific CSS */

@media (max-width: 767px) {

  .artist-page {
    display: flex;
    flex-direction: column;
   
    padding-top: 80px;
  }

  .artist-header {
    position: relative;
    width: 100%;
    
    display: flex;
    justify-content: start;
   
  }

  .artist-content {
    display: flex;

    margin-top: 0;
    gap: 10px;
  }

  .artist-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(4px) grayscale(100%);
    opacity: 0.5;
    z-index: -1;
  }

  .artist-content-img-name {
    justify-content: center;
    gap: 10px;
  }

  .artist-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-top: 10px;
  }

  .artist-name {
    font-size: 24px;
    font-family: 'Nohemi Regular';
    color: #2f2f2f;
  }

  .social-media-links {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }

  .social-media-links img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .about-section {
    width: 90%;
    text-align: left;
    margin: 20px 20px;
  }

  .about-section h2 {
    font-size: 24px;
    font-family: 'Nohemi Regular';
  }

  .about-section p {
    font-size: 16px;
    font-family: 'Nohemi Light';
    color: #2f2f2f;
    margin: 10px 0;
  }

  .category-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  .category-buttons button {
    font-family: 'Nohemi Regular';
    font-size: 16px;
    color: #2f2f2f;
    background-color: white;
    border: 2px solid #2f2f2f;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .category-buttons button.active {
    background-color: #2f2f2f;
    color: white;
  }

  .category-buttons button:hover {
    background-color: #2f2f2f;
    border: 2px solid #2f2f2f;
    color: white;
  }

  .featured-songs {
    width: 100%;
    text-align: center;
  }

  .featured-songs h2 {
    font-size: 24px;
    font-family: 'Nohemi Regular';
    margin-bottom: 20px;
  }

  .featured-songs ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    list-style: none;
    padding: 0;
  }

  .featured-song {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    width: 150px;
    text-align: left;
    transition: transform 0.2s;
  }

  .featured-song:hover {
    transform: scale(1.05);
  }

  .song-thumbnail {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .song-details {
    padding: 10px;
  }

  .song-details span {
    display: block;
    font-family: 'Nohemi Regular';
  }

  .song-details span:first-child {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .song-details span:last-child {
    font-size: 8px;
    color: #666;
  }

  .cards-artist-page {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 20px;
    padding-bottom: 30px;
  }

  .videos-container {
    background-color: transparent;
    gap: 10px;
    flex-wrap: wrap;
    align-items: left;
    justify-content: start;
  }

  .youtube-videos-artist {
    border-radius: 20px;
  }
}
