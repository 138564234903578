/* Basic Navbar Styles */
.navbar {
    /* position: fixed; */
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 1000;
    top: 0;
    background-color: #2f2f2f;
    /* background: linear-gradient(105deg,  #2f2f2f, #f15f3b, #282828); */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);  */
  }

  .profile-icon-nav-bar {
    align-items: center;
    width: 25px;
    height: 25px;
    
  }

  .profile-icon-nav-bar:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    gap: 40px;
    
    margin-right: 10px;
    font-family: 'Nohemi Regular';
  }
  
  .nav-links ul {
    align-items: center;
    display: flex;
    list-style: none;
    gap: 48px;
    margin: 0;
    font-size: 16px;
    padding: 0;
  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }
  
  .hamburger-line {
    width: 30px;
    height: 3px;
    background-color: #f6f6f6;
    margin: 4px 0;
    border-radius: 10px;
  }
  
  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 300px;
    z-index: 1000;
  }

  .icon-profile-dropdown {
    width: 20px;
    height: 20px;
  }
  
  .dropdown-item {
    padding: 10px;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: block;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }


  /* Mobile Styles */
  @media (max-width: 768px) {
    .navbar{
      position: fixed;
    }
    .nav-links {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(47, 47, 47, 0.99); /* Semi-transparent background */
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease, transform 0.5s ease;
      transform: translateY(-100%);
    }
  
    .nav-links.open {
      display: flex;
      opacity: 1;
      font-size: 96px;
      pointer-events: auto;
      transform: translateY(0); 
      
    }
  
    .nav-links ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      text-align: center;
      padding: 0;
      align-items: center;
      margin: 0;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    
  
    .nav-links ul li {
      /* margin-bottom: 5px;  */
      font-size: 36px;
      
    }
  
    .hamburger {
      display: flex;
    }
  
    .exit-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      cursor: pointer;
      color: #fff;
    }

    .profile-icon-nav-bar {
      width: 50px;
      height: 50px;
    }

    
  }
  
  /* Hide exit icon on larger screens */
  @media (min-width: 769px) {
    .exit-icon {
      display: none;
    }
  }
  