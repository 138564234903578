.songs-list {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2f2f2f;
  transition: height 0.5s ease-in-out;
}

.song-item {
  font-family: "Nohemi Regular";
  background-color: #2f2f2f;
  position: relative;
  cursor: none;
  /* Adjust as needed */
  width: 100%;
  max-width: 100%;
  max-height: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b6b6b6;
  display: flex;
  transition: background-color 0.5s ease-in-out, color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.song-item:first-child {
  border-top: 1px solid #b6b6b6;
}

.song-title {
  padding-left: 1.5em;

  font-size: 36px;
  /* Adjust as needed */
  color: #f6f6f6;
  /* Adjust as needed */
}

.song-artist {
  /* width: 80px; */
  display: block;
  text-align: left;
  font-family: 'Nohemi Light';
  padding-right: 50px;
  font-size: 16px;
  color: #f6f6f6;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 0 0 25%;
}


.song-item:hover {
  background-color: #f15f3b;
  /* Background color on hover */
  color: #2f2f2f;
  /* Text color on hover */
}

.song-item:hover .song-title,
.song-item:hover .song-artist {
  color: #2f2f2f;
}

.show-more-button {
  font-family: "Nohemi Regular";
  background-color: #f0f0f0;
  /* Initial background color */
  padding: 10px 20px;
  /* margin: 20px; */
  border: none;
  border-radius: 20px;
  font-size: 16px;
  color: #2f2f2f;
  /* Initial text color */
  cursor: pointer;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out, transform 0.5s ease-in-out;
  /* Smooth transition */
}

/* Hover effects for the button */
.show-more-button:hover {
  background-color: #f15f3b;
  /* Background color on hover */
  color: #f6f6f6;
  /* Text color on hover */
  transform: scale(1.02);
  /* Slightly scale up the button */
}

.button-container {
  display: flex;
  justify-content: center;
  background-color: #2f2f2f;
  /* Background color behind the button */
  padding: 20px;
  width: 100%;
}


/* Floating image style */
.song-item .hover-image {
  cursor: none;
  position: fixed; /* Ensure it's positioned relative to the viewport */
  pointer-events: none; /* Prevents interference with mouse events */
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Show image on hover and follow cursor */
.song-item:hover .hover-image {
  opacity: 1;
}

.song-item:hover .hover-image {
  transform: translate(calc(-50% + 10px), calc(-50% + 10px)); /* Adjust positioning */
}



/* Mobile styles */
@media (max-width: 767px) {

  .songs-list {
    padding-top: 20px;
    background-color: #2f2f2f;
  }

  .song-item {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    justify-content: space-between;
    padding: 0 10px;
    height: 70px;
    border-bottom: 1px solid #c8c8c8;
    flex-direction: row;
    transition: background-color 0.5s ease-in-out, color 0.3s ease-in-out, transform 0.2s ease-in-out;
  }

  .song-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Nohemi Regular';
    color: #f2f2f2;
    font-size: 24px;
  }

  .song-artist {
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Nohemi Light';
    color: #f2f2f2;
    font-size: 8px;
    flex: 0 0 30%;

  }

  .song-title {

    padding-left: 15px;
    /* Adjusted for mobile */
    font-size: 24px;
    /* Adjusted for smaller screens */
    flex: 1;
    /* Allow title to take available space */
  }

  .song-artist {

    padding-right: 15px;
    /* Adjusted for mobile */
    font-size: 8px;
    /* Adjusted for smaller screens */
    text-align: left;
    /* Ensure right alignment */
  }

  .show-more-button {
    font-size: 8px;
    /* Adjusted for smaller screens */
    padding: 8px 16px;
    /* Adjusted padding */
  }

  .button-container {
    padding: 20px;
    /* Adjusted padding for smaller screens */
  }
}