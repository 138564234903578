.ranking-section {
  font-family: 'Nohemi Light';
  margin: 0 10px; /* Set horizontal margins */
  width: calc(100% - 20px); /* Adjust width to account for margins */
  box-sizing: border-box; 
}

.ranking-section h2 {
  margin-bottom: 15px;
}

.ranking-header,
.ranking-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.user-item:last-child {
  border-bottom: none;
}

.user-rank, .user-name, .user-views, .user-songs, .user-news {
  text-align: center;
}

.user-rank {
  width: 50px; /* Adjust width as needed */
}

.user-name {
  width: 30%; /* Adjust the width to fit other columns */
  white-space: nowrap; 
  text-align: left;
  overflow: hidden; 
  text-overflow: ellipsis;
}

.user-views, .user-songs, .user-news {
  width: 20%; /* Adjust width to fit other columns */
}

.top-three {
  color: #f15f3b;
}

.user-rank-container {
  padding-top: 10px;
}

.ranking-header li {
  font-family: 'Nohemi Light';
  font-weight: bold;
}

.user-item span {
  display: inline-block;
  vertical-align: middle;
}

.user-views {
  font-family: 'Nohemi Light';
  text-align: end;
}
