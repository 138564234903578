.scrolling-container {
    /* position: absolute; */
    overflow: hidden;
    background-color: #2f2f2f;
    white-space: nowrap;
    display: flex;
    align-items: center;
    /* margin-top: -5vh;  */
    width: 100%;
    height: 100px;
  }


  
  .scrolling-text {
    font-family: "Nohemi Regular";
    display: flex;
    align-items: center;
    font-size: 100px;
    color: #f6f6f6;
    animation: scroll 60s linear infinite;
  }
  
  .menu-item {
    margin-right: 40px; 
  }
  
  .dot-image {
    width: 40px;
    height: 40px;
    margin-right: 40px;
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  
  }
  