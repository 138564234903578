.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* scroll: non; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2f2f2f;
    z-index: 9999;
    transition: transform 1.2s cubic-bezier(0.99, 0.07, 0.02, 0.93), opacity 0.5s ease-in;
}

.drawer-content {
    text-align: center;
    color: #fff;
}

.splash-title {
    font-size: 48px;
    margin: 10px 0;
    font-family: 'Arial', sans-serif;
}

.splash-logo {
    width: 500px;
    padding: 20px;
    height: auto;
}

.drawer-up {
    transform: translateY(-100vh); /* Move the splash screen completely out of view */
}
