.lyrics-display-page {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.lyrics-display-page {
  transition: all 1s ease;
}

.divider-lyrics-page-lyrics-chords {
  height: 2px;
  width: 90%;
  background-color: #2f2f2f;
  margin-bottom: 20px;
  border: none;
}

.lyrics-chords-title {
  font-family: 'Nohemi Regular';
  font-size: 24px;
  padding-bottom: 20px;
}

.lyrics-chords-title h1{
  font-size: 24px;
}

.lyrics-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px;

}

@keyframes popAndFloat {
  0% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }

  50% {
    opacity: 1;
    transform: scale(1.5) translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: scale(2) translateY(-40px);
  }
}

.floating-like-icon {
  position: fixed;
  width: 400px;
  top: 30%;
  left: 35%;
  transform: translate(-50%, 50%);
  pointer-events: none;
  animation: popAndFloat 2s forwards;
  z-index: 10;
}

.views-likes-container {
  font-size: 16px;
  display: flex;
  font-family: 'Nohemi Light';
  gap: 20px;
  padding-bottom: 20px;
  align-items: start;

}

.views-container {
  align-items: center;
  display: flex;
  gap: 5px;
}

.likes-container {
  align-items: center;
  display: flex;
  gap: 5px;
}

.views-likes-container img {
  width: 30px;
  height: 30px;


}

.lyrics-display-page .show-more-button-lyrics {
  margin-top: 20px;
  padding: 10px 20px;
  border: #2f2f2f;
  border-radius: 20px;
  font-size: 16px;
  color: #2f2f2f;
  cursor: pointer;
}

@media (min-width: 768px) {
  .lyrics-content {
    font-family: 'Nohemi Regular';
    flex-direction: row;
  }
}

.lyrics {

  flex: 1;
  margin-right: 20px;
  margin-left: 50px;
}

.lyrics-display-right-content {
  align-items: center;

}

.related-songs-heading {
  font-family: 'Nohemi Regular';
  padding-top: 0.5em;
  font-size: 36px;
}

.related-songs {
  border-bottom: 1.5px solid #c8c8c8;
  font-family: 'Nohemi Regular';
  font-size: 16px;
  padding: 10px 0px;
  width: 90%;
  /* transition: color 0.5s ease-in-out; */
}

.related-songs :hover {
  color: #f15f3b;
  transition: color 0.2s ease-in-out;

}

.song-title-lyrics-display {
  font-size: 48px;
  padding-top: 40px;
  color: #2f2f2f;
  font-family: 'Nohemi Regular';
}

.loading-lyrics-display-page {
  font-family: 'Nohemi Regular';
  font-size: 48px;
  align-items: center;

}

.song-artist-lyrics-display {
  font-size: 16px;
  color: #2f2f2f;
  margin-bottom: 20px;
  font-family: 'Nohemi Light';
}

.divider-lyrics-page {
  height: 2px;
  width: 90%;
  background-color: #2f2f2f;
  margin-top: 70px;
  border: none;

}

.song-lyrics {
  font-family: 'Nohemi Regular';
  font-size: 48px;
  line-height: 3;
  letter-spacing: 1px;
  color: #2f2f2f;
}

.song-chords {
  font-family: "Nohemi Regular";
  font-size: 36px;
  color: #f15f3b;
  margin-bottom: -35px;
  /* margin-left: -10px; */
}

.youtube-video-lyrics-page {
  flex-shrink: 0;
  /* padding: 20px; */
  width: 100%;
  margin-right: 40px;
  max-width: 700px;
  height: 315px;
}

.youtube-video-lyrics-page iframe {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

@media (min-width: 768px) {
  .youtube-video-lyrics-page {
    width: 560px;
  }
}

.toggle-container {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.toggle-button {
  font-family: 'Nohemi Regular';
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 40px;

  background-color: #f2f2f2;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}

.toggle-option {
  width: 50%;
  text-align: center;
  font-size: 16px;
  color: #2f2f2f;
  z-index: 1;
}

.toggle-button::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 30px;
  background-color: #f15f3b;
  transition: transform 0.4s ease;
}

.toggle-button.telugu::before {
  transform: translateX(0%);
}

.toggle-button.english::before {
  transform: translateX(100%);
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Anek Telugu', sans-serif;
}

.skeleton-title,
.skeleton-artist,
.skeleton-toggle,
.skeleton-lyrics,
.skeleton-video {
  background: #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}

.skeleton-title {
  width: 70%;
  height: 50px;
}

.skeleton-artist {
  width: 50%;
  height: 20px;
}

.skeleton-toggle {
  width: 300px;
  height: 50px;
}

.skeleton-lyrics {
  width: 100%;
  height: 200px;
}

.skeleton-video {
  width: 100%;
  height: 315px;
}

.skeleton-loader::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #ddd 25%, #f5f5f5 50%, #ddd 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}


.font-size-control {
  font-family: 'Nohemi Regular';
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  color: #2f2f2f;
  padding: 10px;
  width: 150px;
  height: 40px;
  border-radius: 30px;
  margin-bottom: 20px;
}




.lyrics-heading-content {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-left: 40px;
}


.background-image-lyrics-display {
  position: absolute;
  background-color: #f5f5f5;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 400px;
  background-size: cover;
  filter: grayscale(100%) brightness(0.9) contrast(0.6);
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.2;
  z-index: -1;
}

.lyrics-heading-content::before {
  filter: blur(4px);
  z-index: -1;
}

.divider-lyrics-page-lyrics-chords {
  height: 2px;
  width: 90%;
  background-color: #2f2f2f;
  margin-bottom: 20px;
  border: none;
}


.lyrics-chords-title {
  font-family: 'Nohemi Regular';
  font-size: 24px;
  padding-bottom: 20px;
}




.font-size-control span {

  margin: 10px;
  font-size: 16px;
}

.font-size-button {
  font-size: 16px;
  /* background-color: #f15f3b; */
  border: none;
  border-radius: 200px;
  color: #2f2f2f;
  width: 40px;


  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.font-size-button:hover {
  border-radius: 200px;
  color: #f15f3b;
}

.song-artist-lyrics-display :hover {

  color: #f15f3b;
  transition: color 0.2s ease;

}

.transpose-control {
  font-family: 'Nohemi Regular';
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  color: #2f2f2f;
  padding: 10px;
  width: 200px;
  height: 40px;
  border-radius: 30px;
  margin-bottom: 20px;
}


.transpose-control span {
  margin: 10px;
  font-size: 16px;



}

.transpose-steps {
  color: #a5a5a5;
  position: relative;


}

.transpose-button {
  font-size: 16px;
  border: none;
  /* border-radius: 200px; */
  color: #2f2f2f;
  width: 40px;

  margin: 0 0px;
  cursor: pointer;
  transition: color 0.3s;
}

.transpose-button:hover {
  color: #f15f3b;
}


.song-artist-lyrics-display .tab-space::before {
  content: "\00a0\00a0\00a0\00a0\00a0";
  /* 5 non-breaking spaces */
}

.controls-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.loading-lyrics-display-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;

}

.logo-loading {
  width: 100px;
  /* Adjust size as needed */
  height: 100px;
  /* Adjust size as needed */
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.2;
  }
}


/* Mobile-specific CSS */

@media (max-width: 767px) {

  .lyrics-display-page {
    display: flex;
    flex-direction: column;
    align-items: center;

  }


  .lyrics-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }

  .lyrics-chords-title {
    font-size: 24px;
  }

  .views-likes-container {
    font-size: 16px;
    display: flex;
    font-family: 'Nohemi Light';
    gap: 10px;
    padding-bottom: 10px;
    align-items: center;
  }

  .views-container,
  .likes-container {
    align-items: center;
    display: flex;
    gap: 5px;
  }

  .views-likes-container img {
    width: 20px;
    height: 20px;
  }

  .lyrics-display-page .show-more-button-lyrics {
    margin-top: 10px;
    padding: 5px 10px;
    border: #2f2f2f;
    border-radius: 20px;
    font-size: 16px;
    color: #2f2f2f;
    cursor: pointer;
  }

  .lyrics {
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
  }

  .lyrics-display-right-content {
    padding: 20px;
    align-items: center;
  }

  .related-songs-heading {
    padding-top: 0.5em;
    font-size: 24px;


  }

  .related-songs {
    border-bottom: 1.5px solid #c8c8c8;
    font-family: 'Nohemi Regular';
    font-size: 16px;
    padding: 5px 0px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .related-songs:hover {
    color: #f15f3b;
    transition: color 0.2s ease-in-out;
  }

  .song-title-lyrics-display {
    font-size: 36px;
    padding-top: 40px;
    color: #2f2f2f;
    font-family: 'Nohemi Regular';
    display: -webkit-box;
    /* Use a flexible box layout model */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    -webkit-line-clamp: 2;
    /* Limit to 2 lines */
    overflow: hidden;
    /* Hide any overflowing content */
    text-overflow: ellipsis;
    /* Display ellipsis (...) for overflow text */
    line-clamp: 2;
    /* Standard property for compatibility */
  }

  .loading-lyrics-display-page {
    font-family: 'Nohemi Regular';
    font-size: 50px;
    align-items: center;
  }

  .song-artist-lyrics-display {
    font-size: 16px;
    color: #2f2f2f;
    margin-bottom: 10px;
    font-family: 'Nohemi Light';


  }

  .song-artist-lyrics-display :hover {

    color: #f15f3b;
    transition: color 0.2s ease;

  }

  .divider-lyrics-page {
    height: 2px;
    width: 90%;
    background-color: #2f2f2f;
    margin-top: 30px;
    border: none;
  }

  .song-lyrics {
    padding-top: 20px;
    font-family: 'Nohemi Regular';
    font-size: 24px;
    line-height: 2.5;
    letter-spacing: 1px;
    color: #2f2f2f;
  }

  .song-chords {
    font-family: 'Nohemi Regular';
    font-size: 24px;
    color: #f15f3b;
    margin-bottom: -20px;
  }

  .youtube-video-lyrics-page {
    flex-shrink: 0;
    width: 100%;
    margin-right: 20px;
    max-width: 100%;
    height: 250px;
  }

  .youtube-video-lyrics-page iframe {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  .toggle-container {
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
  }

  .toggle-button {
    font-family: 'Nohemi Regular';
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 40px;
    background-color: #f2f2f2;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
  }

  .toggle-option {
    width: 50%;
    text-align: center;
    font-size: 16px;
    color: #2f2f2f;
    z-index: 1;
  }

  .toggle-button::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: 20px;
    background-color: #f15f3b;
    transition: transform 0.4s ease;
  }

  .toggle-button.telugu::before {
    transform: translateX(0%);
  }

  .toggle-button.english::before {
    transform: translateX(100%);
  }

  .skeleton-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    font-family: 'Anek Telugu', sans-serif;
  }

  .skeleton-title,
  .skeleton-artist,
  .skeleton-toggle,
  .skeleton-lyrics,
  .skeleton-video {
    background: #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .skeleton-title {
    width: 70%;
    height: 30px;
  }

  .skeleton-artist {
    width: 50%;
    height: 15px;
  }

  .skeleton-toggle {
    width: 200px;
    height: 30px;
  }

  .skeleton-lyrics {
    width: 100%;
    height: 100px;
  }

  .skeleton-video {
    width: 100%;
    height: 200px;
  }

  .skeleton-loader::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #ddd 25%, #f5f5f5 50%, #ddd 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }

  .font-size-control {
    font-family: 'Nohemi Regular';
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    color: #2f2f2f;
    padding: 5px;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .font-size-control span {
    margin: 5px;
    font-size: 16px;
  }

  .font-size-button {
    font-size: 16px;
    border: none;
    border-radius: 200px;
    color: #2f2f2f;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .font-size-button:hover {
    border-radius: 200px;
    color: #f15f3b;
  }

  .transpose-control {
    font-family: 'Nohemi Regular';
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    color: #2f2f2f;
    padding: 5px;
    width: 200px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .transpose-control span {
    margin: 5px;
    font-size: 16px;
  }

  .transpose-steps {
    color: #a5a5a5;
    position: relative;
  }

  .transpose-button {
    font-size: 16px;
    border: none;
    color: #2f2f2f;
    width: 30px;
    margin: 0 2px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .transpose-button:hover {
    color: #f15f3b;
  }

  .song-artist-lyrics-display .tab-space::before {
    content: "\00a0\00a0\00a0\00a0\00a0";
  }

  .controls-container {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }

  .loading-lyrics-display-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .logo-loading {
    width: 100px;
    height: 100px;
    animation: pulse 2s infinite;
  }


  .lyrics-heading-content {
    margin-top: 40px;
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;


  }


  .background-image-lyrics-display {
    position: absolute;
    background-color: #f5f5f5;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 400px;
    background-size: cover;
    filter: grayscale(100%) brightness(0.9) contrast(0.6);
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: -1;
  }


}



.comments-section {
  width: 100%;
  margin: 30px 0;
}

.comments-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment {
  padding: 10px;
  border: 1px solid #c8c8c8;
  border-radius: 8px;
}

.comment-actions {
  display: flex;
  gap: 10px;
}

.add-comment textarea {
  width: 100%;
  height: 80px;
  border-radius: 8px;
  border: 1px solid #c8c8c8;
  padding: 10px;
  font-family: 'Nohemi Light';
  font-size: 16px;
}

.add-comment button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #f15f3b;
  color: #fff;
  cursor: pointer;
  font-family: 'Nohemi Light';
  font-size: 16px;
}

.add-comment button:hover {
  background-color: #e14c29;
}

.like-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.like-button-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.like-button-container {
  align-items: center;
  transition: opacity 0.3s ease, transform 0.3s ease;

}

/* body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.lyrics-display-page.dark-mode {
  background-color: #121212;
  color: #ffffff;
} */
/* 
.divider-lyrics-page-lyrics-chords.dark-mode,
.lyrics-display-page .show-more-button-lyrics.dark-mode,
.song-title-lyrics-display.dark-mode,
.song-artist-lyrics-display.dark-mode,
.song-lyrics.dark-mode,
.transpose-control.dark-mode,
.font-size-control.dark-mode,
.song-title-lyrics-display.dark-mode,
.lyrics-display-page h1.dark-mode,
.lyrics-heading-content.dark-mode,
.background-image-lyrics-display.dark-mode,
.youtube-video-lyrics-page.dark-mode,
.related-songs-heading.dark-mode,
.related-songs.dark-mode,
.views-likes-container.dark-mode,
.views-container.dark-mode,
.likes-container.dark-mode,
.font-size-button.dark-mode,
.transpose-button.dark-mode {
  background-color: #1f1f1f;
  color: #ffffff;
} */