/* LyricsEditPage.css */

/* Container for the entire page */
.lyrics-edit-page {
    display: flex;
    flex-direction: column;
    align-items: center;
   
    font-family: 'Nohemi Regular', sans-serif;
}

/* Form styling */
.lyrics-edit-page form {
    width: 100%;
    max-width: 800px;
    
    border-radius: 10px;
    padding: 20px;
   
}

/* Form group container */
.lyrics-edit-page div {
    
    margin-bottom: 20px;
}

/* Label styling */
.lyrics-edit-page label {
    padding-top: 10px;
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
}

/* Input styling */
.lyrics-edit-page input[type="text"],
.lyrics-edit-page input[type="number"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.lyrics-edit-page input[type="text"]:focus,
.lyrics-edit-page input[type="number"]:focus {
    border-color: #f15f3b;
    outline: none;
}

/* Button styling */
.lyrics-edit-page button {
    background-color: #f15f3b;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.lyrics-edit-page button:hover {
    background-color: #e14b27;
}

/* Loading text */
.lyrics-edit-page p {
    font-size: 16px;
    color: #555;
}

/* Container for side-by-side lyrics */
.lyrics-side-by-side {
    display: flex;
    gap: 20px; /* Adjust the gap between the two sections */
}

/* Individual lyrics container */
.lyrics-side-by-side > div {
    flex: 1;
}

.tags-edit-page{
   color: #f15f3b; 
   grid-template-rows: 10px;
}

/* Ensure inputs within side-by-side layout take full width */
.lyrics-side-by-side input[type="text"] {
    width: 100%;
    margin-bottom: 10px; /* Space between lyrics inputs */
}

/* Container for tags */
.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    border-radius: 15px;
    
  }
  
  /* Styling for individual tag items */
  .tag-item {
    background-color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  /* Remove tag button styling */
  .remove-tag-button {
    background: none;
    border: none;
    color: #f15f3b;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* Input field for new tag */
  .tag-input {
    flex-grow: 1;
    padding: 1px 0px;
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  /* Add tag button styling */
  .add-tag-button-edit {
    padding: 5px 10px;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
  }
  
