.dashboard {
    display: flex;
}

.profile,
.songs-section,
.dashboard-content {
   padding: 30px;
   
    
}

.dashboard-menu-items{
    padding-top: 30px;
    width: 120px;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 20px;
}



.profile-user-display-name {
    
    font-size: 16px;
    padding-top: 20px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 200px; 
}

.profile-user-display-rank{
    padding-top: 5px;
    font-size: 16px;
    font-family: 'Nohemi Light';
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 200px; 
}

.rank-number{
    color: #f15f3b;
    font-family: 'Nohemi Regular';
}

.profile-section {
    padding: 30px;
    width: 300px;
    height: 80vh;
    background-color: #fbfbfb;
    font-family: 'Nohemi Regular';
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    transition: width 0.3s ease;
}

.profile-image-display-name{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vertical-divider {
    width: 3px;
    height: 100%;
    background-color: #c75a5a;
    margin: 160px 20px;
    flex-shrink: 0;
}

.dashboard-title-search-bar {
    width: 70vw;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    align-items: center;
   
}

.dashboard-search-bar {
    border: 2px solid #ddd;
    border-radius: 50px;
    height: 30px;
    padding: 18px;
    font-family: 'Nohemi Light';
    outline: none;
    width: 300px;
}

.dashboard-search-bar:focus {
    border-color: #2f2f2f;
}

.dashboard-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 1;
    transform: translateY(0);
}

.not-available-text {
    font-family: 'Nohemi Light';
    font-size: 16px;
}

.profile-user-list-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'Nohemi Light';
    padding: 10px 0px;
    font-size: 16px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.logo-loading-user-dashboard {
    align-items: center;
}

.category-song-card-user-profile {
    display: block;
    width: 240px;
    height: 150px;
    border: 1px solid #ededed;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 10px;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
    background-color: #fff;
    transition: transform 1s ease, background-color 0.3s ease;
}

.category-song-card-user-profile:hover {
    transform: scale(1.05);
    background-color: #f15f3b;
    color: #fff;
}

.profile-user-list-item:hover {
    color: #f15f3b;
    
}

.card-actions {
    font-family: 'Nohemi Light';
    display: flex;
    justify-content: end;
    gap: 20px;
    padding: 10px;
}

.edit-delete-button {
    font-size: 16px;
    transition: color 0.4s ease, transform 0.4s ease;
}

.edit-delete-button:hover {
    color: #f15f3b;
    transform: scale(1.08);
}

.items-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-items: start;
    gap: 50px;
    
}

.artist-item {
    align-items: center;
   
}

.items-grid-artists {
    display: flex;
    
    gap: 20px;
}

.artist-item-dashboard {
    list-style-type: none;
    transform: scale(0.8);
}

.artist-item-dashboard:hover {
    transform: scale(0.85);
}

.profile-user-list-item:first-child {
    padding-top: 20px;
}

.profile-user-dashboard-title {
    font-family: 'Nohemi Regular';
    font-size: 36px;
}


.category-song-card-user-profile-add {
  display: block;
  width: 240px;
  height: 150px;
  border: 1px solid #ededed;
  border-radius: 20px;
  overflow: hidden;
  align-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #fff;
  transition: transform 1s ease, background-color 0.3s ease;
}

.category-song-card-user-profile-add:hover {
  transform: scale(1.05);
  background-color: #f15f3b;
  color: #fff;
}


.profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.plus-symbol{
  color: #ddd;
  font-size: 24px;
}

.profile ul {
    list-style-type: none;
    padding: 0;
}

.songs-grid {
    display: grid;
    gap: 10px;
}

.song-card {
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-section.collapsed {
  width:  60px;
}

.placeholder {
    width: 100px;
    height: 100px;
    background-color: #f0f0f0;
    margin-bottom: 10px;
}

.show-more {
    margin-top: 10px;
    padding: 5px 10px;
}

.profile-user-list-item.active {
    font-family: 'Nohemi Regular';
    color: #f15f3b;
  
}

.show-more-button-dashboard {
    /* align-self: center; */
    font-family: "Nohemi Regular";
    padding: 8px 16px;
    border: none;
    border-radius: 24px;
    margin-top: 2dvb;
    font-size: 16px;
    color: #2f2f2f;
    width: 120px;
    background-color: #f2f2f2;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.show-more-button-dashboard:hover {
    background-color: #f15f3b;
    color: white;
}

.profile-section {
  transition: all 0.3s ease;
}

.profile-section.collapsed .profile-image-display-name h2,
.profile-section.collapsed .dashboard-menu-items li {
  display: none;
}

.profile-section.collapsed .profile-image-display-name img,
.profile-section.collapsed .dashboard-menu-items img {
  display: block;
  width: 20px; /* Ensure images are visible with appropriate width */
  height: auto; /* Maintain aspect ratio */
}

.collapse-toggle {
  display: block;
  margin-top: 10px;
}


/* Base mobile styles for small screens */
@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    padding-top: 100px;
  }

  .profile,
  .songs-section,
  .dashboard-content {
    padding: 30px;
  }

  .dashboard-menu-items {
    width: 100%;
    padding-top: 15px;
    gap: 15px;
  }

  .profile-section {
    width: 100%;
    height: auto; /* Allow height to adjust based on content */
    padding: 15px;
  }

  .profile-pic {
    width: 100px;
    height: 100px;
  }

  .profile-user-display-name,
  .profile-user-display-rank {
    font-size: 16px;
    max-width: 100%; /* Remove max-width restriction */
    overflow: visible; /* Ensure text is not truncated */
    text-overflow: clip;
    white-space: normal; /* Allow text to wrap */
  }

  .dashboard-title-search-bar {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 15px;
  }

  .dashboard-search-bar {
    width: 100%;
    margin-top: 10px;
  }

  .vertical-divider {
    display: none; /* Hide vertical divider on mobile */
  }

  .category-song-card-user-profile {
    width: 200px;
    height: 140px; 
    padding-left: 15px;
    padding-right: 15px;
  }

  .items-grid {
    gap: 20px;
    justify-content: center;
  }

  .plus-symbol{
    color: #ddd;
  }

  .artist-item,
  .items-grid-artists {
    flex-direction: column; /* Stack artist items vertically */
    align-items: center;
  }

  .profile-user-list-item {
    font-size: 16px;
    padding: 10px;
  }

  .show-more-button-dashboard {
    width: 100%;
    font-size: 16px;
    padding: 10px;
  }

  .profile-section.collapsed {
    width: 60px;
    height: auto; /* Ensure the height is adjusted to fit content */
  }

  .profile-section.collapsed .profile-image-display-name img {
    width: 30px; /* Adjust image size for better visibility */
  }
}
