/* EditArtistUnique.css */

/* Container for the entire page */
.unique-edit-artist-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: 'Nohemi Regular', sans-serif;
   
    

}

/* Container for each playlist item */
.playlist-item-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 800px; /* Match form width */
    margin-bottom: 10px; /* Space between items */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

/* Input field styling for playlist items */
.playlist-item-input {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-right: 10px; /* Space between input and button */
}

.playlist-item-input:focus {
    border-color: #f15f3b;
    outline: none;
}

.add-playlist-button {
    background-color: #f15f3b; /* Green background */
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px; /* Space below the button */
}

/* Button styling for removing playlist items */
.remove-playlist-button {
    background-color: #ddd;
    color: #2f2f2f;
    border: none;
    border-radius: 30px;
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-playlist-button:hover {
    background-color: #f15f3b;
    color: white;
}


.edit-artist-page{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.unique-edit-artist-heading{
    font-family: 'Nohemi Regular';
    font-size: 48px;
    padding-top: 30px;
}

.edit-artist-container{
    display: flex;
    flex-direction: column;
    font-family: 'Nohemi Regular';
    padding: 30px;
    width: 70%;
}

.current-artist-image{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 10px;
}

/* Form styling */
.unique-edit-artist-form {
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

/* Form group container */
.unique-edit-artist-form > div {
    margin-bottom: 20px;
}

/* Label styling */
.unique-edit-artist-label {
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
}

/* Input and textarea styling */
.unique-edit-artist-input,
.unique-edit-artist-textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.unique-edit-artist-input:focus,
.unique-edit-artist-textarea:focus {
    border-color: #f15f3b;
    outline: none;
}

/* File input styling */
.unique-edit-artist-file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 100%; /* Ensure file container matches the form width */
}

.unique-edit-artist-file-input {
    width: 100%;
    margin-bottom: 10px;
}

/* Image display styling */
.unique-edit-artist-image-container {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #f15f3b;
    border-radius: 10px;
    padding: 10px;
    width: 100%; /* Ensure image container matches the form width */
    max-width: 800px; /* Limit the width of the image container */
}

.unique-edit-artist-image {
    max-width: 100%;
    max-height: 200px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.unique-edit-artist-uploading {
    font-size: 16px;
    color: #f15f3b;
}

/* Button styling */
.unique-edit-artist-button {
    align-self: center;
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
    padding: 10px;
    background-color: #f15f3b;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.unique-edit-artist-button:hover {
    background-color: #e14b27;
}

/* Update message styling */
.unique-edit-artist-update-message {
    color: #f15f3b;
    font-size: 16px;
    margin-top: 20px;
}

/* Loading indicator */
.loading-indicator {
    font-size: 16px;
    color: #333;
}

/* Playlist styling */
.unique-edit-artist-playlist-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensure playlist container matches the form width */
}

.unique-edit-artist-playlist-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%; /* Ensure playlist items match the form width */
}

.unique-edit-artist-playlist-item input {
    flex-grow: 1;
    margin-right: 10px;
}

.unique-edit-artist-playlist-button {
    background-color: #f15f3b;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.unique-edit-artist-playlist-button:hover {
    background-color: #e14b27;
}

.unique-edit-artist-playlist-remove {
    background: none;
    border: none;
    color: #f15f3b;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
}
