.toolbar {
    border-bottom: 1px solid #ccc;
    padding: 5px;
    background-color: #f5f5f5;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.toolbar button,
.toolbar select {
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
}

.editor {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
}

.rich-text-editor-container {
    margin: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-height: 300px;
    background-color: #fff;
  }
  
  .ProseMirror {
    outline: none;
    padding: 15px;
    font-size: 16px;
    line-height: 1.6;
  }
  