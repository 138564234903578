/* LyricsEditPage.css */

.unauthorized-page {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 80vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
   
  }
  
  .unauthorized-logo-text {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text */
  }
  
  .unauthorized-logo {
    width: 100px; /* Set logo width */
  }
  
  .unauthorized-logo-text p {
    font-family: 'Nohemi Light';
    margin-top: 20px; /* Add space between logo and text */
    font-size: 16px; /* Adjust font size as needed */
  }
  