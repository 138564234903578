.comments-section-container {
    width: 100%;
    padding-top: 100px;
    /* max-width: 700px; */
    
  }
  
  .comment-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-top: 50px;
    width: 90%;
  }
  
  .comment-textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 20px;
  }

  .comment-textarea:focus {
    border: 1px solid #2f2f2f;
    outline: none;
    
  }
  
  .comment-submit-button {
    padding: 0.5rem;
    border: none;
    align-self: end;
    border-radius: 20px;
    background-color: #f0f0f0;
    width: 100px;
    color: #2f2f2f;
    cursor: pointer;
  }
  
  .comment-submit-button:hover {
    background-color: #f15f3b;
  }
  
  .comments-list-container {
    margin-top: 1rem;
  }
  
  .comment-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
  
  .comment-profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;
  }
  
  .comment-details {
    display: flex;
    flex-direction: column;
  }
  
  .comment-author {
    font-weight: bold;
    margin-bottom: 0.2rem;
  }
  
  .comment-timestamp {
    color: #888;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .comment-content {
    font-size: 1rem;
  }
  
  .loading-text {
    color: #888;
    font-size: 1rem;
  }
  

  .comment-actions {
    margin-top: 10px;
  }
  
  .comment-edit-button,
  .comment-delete-button,
  .comment-cancel-edit-button {
    font-size: 8px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 5px;
  }

  .comments-title {
    padding-top: 20px;
    font-size: 36px;
     padding-bottom: 20px, 
     
  }
  
  .comment-edit-button {
    background-color: #d2d2d2;
  }
  
  .comment-delete-button {
    background-color: #d2d2d2;
  }
  
  .comment-cancel-edit-button {
    background-color: #6c757d;
  }
  
  .comment-edit-button:hover,
  .comment-delete-button:hover,
  .comment-cancel-edit-button:hover {
    opacity: 0.8;
  }
  

  .login-form-container {
    margin-bottom: 20px;
  }
  
  .form-group-container {
    margin-bottom: 15px;
  }
  
  .input-label {
    text-align: left;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .auth-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ddd;
    border: none;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .auth-button-items{
    align-items: center;
    display: flex;
    padding: 5px;
  }
  
  .auth-logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  .comment-form-wrapper {
    position: relative;
  }
  
  .textarea-container {
    position: relative;
  }
  
  .emoji-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .emoji-picker-inside {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    width: 100%;
    background-color: white;
  }
  
  .comment-textarea {
    width: 100%;
    box-sizing: border-box;
  }


  @media (max-width: 768px) {
    .comments-section-container {
      font-family: 'Nohemi Regular';
      width: 100%;
      padding-top: 50px;
    }
  
    .comment-form {
      display: flex;
      flex-direction: column;
      margin: 1rem 0;
    }
  
    .comment-textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 20px;
    }

    .comments-title {
      padding-top: 20px;
      font-size: 24px;
       padding-bottom: 20px, 
       
    }

    
  
    .comment-submit-button {
      padding: 0.5rem;
      border: none;
      border-radius: 30px;
      background-color: #f0f0f0;
      width: 30%;
      color: #2f2f2f;
      cursor: pointer;
    }
  
    .comment-submit-button:hover {
      background-color: #f15f3b;
    }
  
    .comments-list-container {
      margin-top: 1rem;
    }
  
    .comment-item {
      /* display: flex; */
      align-items: flex-start;
      margin-bottom: 1rem;
      flex-direction: row;
    }
  
    .comment-profile-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  
    .comment-details {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .comment-author {
      /* font-weight: bold; */
      margin-bottom: 0.2rem;
    }
  
    .comment-timestamp {
      color: #888;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }
  
    .comment-content {
      font-size: 0.9rem;
    }
  
    .loading-text {
      color: #888;
      font-size: 0.9rem;
    }
  
    .comment-actions {
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
    }
  
    .comment-edit-button,
    .comment-delete-button,
    .comment-cancel-edit-button {
      font-size: 0.8rem;
      background-color: #007bff;
      color: white;
      border: none;
      padding: 5px 10px;
      border-radius: 10px;
      cursor: pointer;
      margin-right: 5px;
    }
  
    .comment-edit-button {
      background-color: #d2d2d2;
    }
  
    .comment-delete-button {
      background-color: #d2d2d2;
    }
  
    .comment-cancel-edit-button {
      background-color: #6c757d;
    }
  
    .comment-edit-button:hover,
    .comment-delete-button:hover,
    .comment-cancel-edit-button:hover {
      opacity: 0.8;
    }
  
    .login-form-container {
      margin-bottom: 15px;
    }
  
    .form-group-container {
      margin-bottom: 10px;
    }
  
    .input-label {
      text-align: left;
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
  
    .input-field {
      width: 100%;
      padding: 6px;
      border: 1px solid #ddd;
      border-radius: 30px;
    }
  
    .submit-button {
      
      color: white;
      border: none;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      width: 40%;
    }
  
    .auth-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ddd;
      border: none;
      padding: 10px;
      margin-top: 10px;
      border-radius: 5px;
      cursor: pointer;
      width: 100%;
    }
  
    .auth-button-items {
      align-items: center;
      display: flex;
      padding: 5px;
    }
  
    .auth-logo {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  
    .error-message {
      color: red;
      font-size: 12px;
      margin-bottom: 10px;
    }
  
    .comment-form-wrapper {
      position: relative;
    }
  
    .textarea-container {
      position: relative;
    }
  
    .emoji-button {
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  
    .emoji-picker-inside {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1000;
      width: 100%;
      background-color: white;
    }
  
    .comment-textarea {
      width: 100%;
      box-sizing: border-box;
    }
  }
  