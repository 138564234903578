.footer {
    background-color: #2f2f2f; /* Background color of the footer */
    color: #f6f6f6; /* Text color */
    padding-top: 80px;
    padding-bottom: 40px;
    padding-left: 30px;
    text-align: start;
    font-family: 'Nohemi Regular';

    
  }
  
  .footer-content {
    /* max-width: 1200px; */
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-logo .logo {
    width: 60%; /* Adjust the size of your logo */
    margin-bottom: 20px;
  }
  
  .footer-social {
    margin-bottom: 20px;
  }
  
  .footer-social a {
    margin: 0 10px;
    display: inline-block;
    transition: transform 0.5s ease-in-out; /* Smooth transition for hover effect */
  }
  
  .footer-social a img {
    width: 40px; /* Adjust the size of the social icons */
  }
  
  .footer-social a:hover {
    transform: scale(1.1); /* Slightly scale up the icon on hover */
  }
  
  .footer-links {
    
    margin-bottom: 20px;
  }
  
  .footer-link {
    color: #fff;
    padding-right: 20px;
    /* margin: 0 15px; */
    text-decoration: none;
    transition: color 0.3s ease-in-out; /* Smooth transition for hover effect */
  }
  
  .footer-link:hover {
    color: #f15f3b; /* Change color on hover */
  }
  
  .footer-copyright p {
    margin: 5px 0;
    font-size: 16px; /* Adjust the font size as needed */
  }
  
  @media (max-width: 767px) {

    .footer {
      padding-top: 40px; /* Adjust padding for mobile */
      padding-bottom: 20px; /* Adjust padding for mobile */
      padding-left: 15px; /* Adjust padding for mobile */
      text-align: left; /* Left-align text and content */
    }
    
    .footer-content {
      display: flex;
      flex-direction: column; /* Stack content vertically */
      align-items: flex-start; /* Align items to the start (left) */
    }
    
    .footer-logo .logo {
      width: 80%; /* Adjust the size of your logo for mobile */
      margin-bottom: 20px; /* Space below logo */
    }
    
    .footer-social {
      margin-bottom: 20px; /* Space below social icons */
    }
    
    .footer-social a {
      margin: 0 10px; /* Space between social icons */
      display: inline-block;
    }
    
    .footer-social a img {
      width: 30px; /* Adjust size of social icons for mobile */
    }
    
    .footer-links {
      margin-bottom: 20px; /* Space below links section */
    }
    
    .footer-link {
      color: #fff;
      padding: 5px 0; /* Padding for mobile */
      text-decoration: none;
      transition: color 0.3s ease-in-out;
      display: block; /* Make each link occupy full width */
    }
    
    .footer-link:hover {
      color: #f15f3b; /* Change color on hover */
    }
    
    .footer-copyright p {
      margin: 5px 0;
      text-align: left;
      font-size: 16px; /* Adjust font size for mobile */
    }
  }